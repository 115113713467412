"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformUpdateAuthority = exports.transformCreateAuthority = exports.transformArraySelectTypeToIds = exports.transformStructuralUnitParams = exports.transformPositionsParams = exports.transformPositionParams = exports.transformStructuralUnitSortingParams = exports.updateParentNode = exports.userAttachParams = exports.updateAndRemoveNode = exports.closeNode = exports.openNode = exports.updateChildNodes = void 0;
const types_1 = require("api/references/employees/types");
const parsers_1 = require("api/parsers/parsers");
const updateChildNodes = (nodes, parentId, parentIdentifier, children) => {
    return nodes.map(node => {
        const isOpen = node.identifier === parentIdentifier ? true : node.isOpen;
        if (node.id === parentId) {
            return { ...node, isOpen, children };
        }
        else if (node.children.length > 0) {
            return { ...node, isOpen, children: (0, exports.updateChildNodes)(node.children, parentId, parentIdentifier, children) };
        }
        return node;
    });
};
exports.updateChildNodes = updateChildNodes;
const openNode = (data, parentIdentifier) => {
    return data.map(node => {
        if (node.identifier === parentIdentifier) {
            return { ...node, isOpen: true };
        }
        else if (node.children.length > 0) {
            return { ...node, children: (0, exports.openNode)(node.children, parentIdentifier) };
        }
        return node;
    });
};
exports.openNode = openNode;
const closeNode = (data, parentIdentifier, targetsChildren = false) => {
    return data.map(node => {
        if (node.identifier === parentIdentifier) {
            return { ...node, isOpen: false, children: (0, exports.closeNode)(node.children, parentIdentifier, true) };
        }
        else if (node.children.length > 0) {
            return { ...node, isOpen: targetsChildren ? false : node.isOpen, children: (0, exports.closeNode)(node.children, parentIdentifier, targetsChildren) };
        }
        return node;
    });
};
exports.closeNode = closeNode;
const updateAndRemoveNode = (nodes, targetId, parentIds) => {
    return nodes
        .map(node => {
        const isParent = parentIds.includes(node.id);
        const filteredChildren = isParent ? node.children.filter(child => child.id !== targetId) : node.children;
        return {
            ...node,
            isUpdated: isParent,
            children: (0, exports.updateAndRemoveNode)(filteredChildren, targetId, parentIds)
        };
    });
};
exports.updateAndRemoveNode = updateAndRemoveNode;
const userAttachParams = (params) => {
    return {
        structuralUnitId: params.structuralUnit?.id,
        userId: params.user?.id,
        startDate: (0, parsers_1.transformDateParams)(params.startDate),
        endDate: (0, parsers_1.transformDateParams)(params.endDate)
    };
};
exports.userAttachParams = userAttachParams;
const updateParentNode = (unitId, parentUnitId, structuralUnitsData, stateData) => {
    return stateData.map(unit => {
        if (unit.id === parentUnitId) {
            return {
                ...unit,
                isOpen: true,
                hasChildren: structuralUnitsData.length > 0,
                children: structuralUnitsData.map(child => (child.id === unitId
                    ? { ...child, isUpdated: true }
                    : child))
            };
        }
        if (unit.children.length > 0) {
            return {
                ...unit,
                children: (0, exports.updateParentNode)(unitId, parentUnitId, structuralUnitsData, unit.children)
            };
        }
        return unit;
    });
};
exports.updateParentNode = updateParentNode;
const transformStructuralUnitSortingParams = (data) => {
    return {
        data: data.map((el, index) => {
            return {
                id: Number(el.id),
                sort: index + 1
            };
        })
    };
};
exports.transformStructuralUnitSortingParams = transformStructuralUnitSortingParams;
const transformPositionParams = (params) => {
    return {
        positionId: params.positionName.id,
        parentId: params.parent.id,
        basicFunctionDuties: params.basicFunctionDuties,
        positionStatus: params.positionStatus.id,
        positionTypeId: params.positionType.id,
        edocUser: params.edocUser ? types_1.EdocUserStatus.USER : types_1.EdocUserStatus.NOT_USER,
        workDirectionId: params.workDirection?.id || null,
        minSalaryAmount: params.positionStatus?.id === 1 ? params.minSalaryAmount : undefined,
        maxSalaryAmount: params.positionStatus?.id === 1 ? params.maxSalaryAmount : undefined
    };
};
exports.transformPositionParams = transformPositionParams;
const transformPositionsParams = (params) => {
    return {
        ...(0, exports.transformPositionParams)(params),
        quantity: params.quantity || 1
    };
};
exports.transformPositionsParams = transformPositionsParams;
const transformStructuralUnitParams = (params) => {
    return {
        name: params.structuralUnitName,
        parentIds: params.parents.map(el => el.id)
    };
};
exports.transformStructuralUnitParams = transformStructuralUnitParams;
const transformArraySelectTypeToIds = (params) => {
    return {
        ids: params.map(el => el.id)
    };
};
exports.transformArraySelectTypeToIds = transformArraySelectTypeToIds;
const transformCreateAuthority = (params, unitId) => {
    return {
        accountId: params.authorizerStructuralUnit.accountId,
        authorizedForAccountId: params.authorizeGranter?.accountId ? params.authorizeGranter.accountId : null,
        authorizedForStructuralUnitId: !params.authorizeGranter?.accountId ? unitId : null,
        comment: params.comment || undefined,
        startDate: (0, parsers_1.transformDateParams)(params.startDate),
        endDate: (0, parsers_1.transformDateParams)(params.endDate),
        orderDate: (0, parsers_1.transformDateParams)(params.orderDate),
        orderNumber: params.orderNumber,
        orderUid: params.orderFile?.id
    };
};
exports.transformCreateAuthority = transformCreateAuthority;
const transformUpdateAuthority = (params) => {
    return {
        endDate: (0, parsers_1.transformDateParams)(params.endDate)
    };
};
exports.transformUpdateAuthority = transformUpdateAuthority;
