"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseCompanyBankAccount = exports.parseCompanyResidencyFile = exports.parseCompanyAddresses = exports.parseCompanySignatories = exports.parseCompany = exports.parseCompanies = exports.parseCompaniesForSelect = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helpers_1 = require("src/modules/references/companies/helpers");
const helper_1 = require("api/helper");
const statuses_1 = require("src/modules/references/companies/helpers/statuses");
const parsers_1 = require("api/parsers/parsers");
const parseCompaniesForSelect = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const companies = response.content;
    try {
        return {
            data: companies.data.map(el => ({
                id: el.id,
                label: el.attributes.name + ' - ' + el.attributes.legalStatusShortName
            })),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: response.errors };
    }
};
exports.parseCompaniesForSelect = parseCompaniesForSelect;
const parseCompanies = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const companies = response.content;
    try {
        return {
            data: companies.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    identificationNumber: el.attributes.identificationNumber,
                    name: el.attributes.name,
                    status: el.attributes.status,
                    legalForm: el.attributes.legalForm,
                    legalStatus: el.attributes.legalStatus,
                    legalStatusName: el.attributes.legalStatusName,
                    legalStatusShortName: el.attributes.legalStatusShortName,
                    resident: el.attributes.resident,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(companies.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCompanies = parseCompanies;
const parseCompany = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const companies = response.content;
    try {
        return {
            data: {
                id: companies.data.id,
                attributes: {
                    identificationNumber: companies.data.attributes.identificationNumber,
                    name: companies.data.attributes.name,
                    legalStatus: statuses_1.legalStatusOptions.find(el => el.id === String(companies.data.attributes.legalStatus)) || null,
                    legalStatusShortName: companies.data.attributes.legalStatusShortName,
                    status: companies.data.attributes.status,
                    foreignCompany: companies.data.attributes.foreignCompany ? apiGlobalTypes_1.YesNoStatus.YES : apiGlobalTypes_1.YesNoStatus.NO,
                    isResident: companies.data.attributes.isResident ? apiGlobalTypes_1.YesNoStatus.YES : apiGlobalTypes_1.YesNoStatus.NO,
                    hasActiveResidencyFile: companies.data.attributes.hasActiveResidencyFile
                },
                addresses: companies.data.relationships.addresses.data.map(el => {
                    return {
                        id: el.id,
                        address: el.attributes.address,
                        type: helpers_1.companyAddressTypeOptions.find(address => address.id === el.attributes.type) || null,
                        status: el.attributes.isActive
                    };
                }),
                bankAccounts: companies.data.relationships.bankAccounts.data.map(el => {
                    return {
                        id: el.id,
                        code: el.attributes.code,
                        accountNumber: el.attributes.accountNumber,
                        name: el.attributes.name,
                        status: el.attributes.status
                    };
                }),
                signatories: companies.data.relationships.signatories.data.map(el => {
                    return {
                        id: el.id,
                        firstname: el.attributes.firstname,
                        lastname: el.attributes.lastname,
                        position: el.attributes.position,
                        phones: el.relationships.phones?.data.map(el => ({
                            id: el.id,
                            phone: el.attributes.phone
                        })) || []
                    };
                }),
                residencyFiles: companies.data.relationships.residencyFiles?.data.map(el => ({
                    year: el.attributes.year,
                    status: el.attributes.isActive ? apiGlobalTypes_1.YesNoStatus.YES : apiGlobalTypes_1.YesNoStatus.NO,
                    file: (0, parsers_1.parseFile)(el.relationships.mediaFile.data)
                })) || []
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCompany = parseCompany;
const parseCompanySignatories = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const companies = response.content;
    try {
        return {
            data: companies.data.relationships.signatories.data.map(el => {
                return {
                    id: el.id,
                    firstname: el.attributes.firstname,
                    lastname: el.attributes.lastname,
                    position: el.attributes.position,
                    phones: el.relationships.phones?.data.map(el => ({
                        id: el.id,
                        phone: el.attributes.phone
                    })) || []
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCompanySignatories = parseCompanySignatories;
const parseCompanyAddresses = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const companies = response.content;
    try {
        return {
            data: companies.data.relationships.addresses.data.map(el => {
                return {
                    id: el.id,
                    address: el.attributes.address,
                    type: helpers_1.companyAddressTypeOptions.find(address => address.id === el.attributes.type) || null,
                    status: el.attributes.isActive
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCompanyAddresses = parseCompanyAddresses;
const parseCompanyResidencyFile = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const companies = response.content;
    try {
        return {
            data: {
                hasActiveResidencyFile: companies.data.attributes.hasActiveResidencyFile,
                items: companies.data.relationships.residencyFiles.data.map(el => {
                    return {
                        id: el.id,
                        status: el.attributes.isActive ? apiGlobalTypes_1.YesNoStatus.YES : apiGlobalTypes_1.YesNoStatus.NO,
                        year: el.attributes.year,
                        file: (0, parsers_1.parseFile)(el.relationships.mediaFile.data)
                    };
                })
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCompanyResidencyFile = parseCompanyResidencyFile;
const parseCompanyBankAccount = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const companies = response.content;
    try {
        return {
            data: companies.data.relationships.bankAccounts.data.map(el => {
                return {
                    id: el.id,
                    accountNumber: el.attributes.accountNumber,
                    code: el.attributes.code,
                    name: el.attributes.name,
                    status: el.attributes.status
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCompanyBankAccount = parseCompanyBankAccount;
