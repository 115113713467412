"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProcurementMultiYearHistory = exports.deleteProcurementMultiYear = exports.getProcurementMultiYear = exports.createOrUpdateProcurementMultiYear = exports.getAdditionalFiles = exports.addAdditionalFiles = exports.updateProcurementTemplate = exports.getProcurementTemplate = exports.getLegalBasicsForSelect = exports.deleteProcurementFile = exports.getCorrectionCalculationsV2 = exports.getCorrectionCalculations = exports.updateProcurementItem = exports.getItemsForProcurement = exports.generateProcurementTemplate = exports.getProcurementCalculateItemsV2 = exports.getProcurementCalculateItems = exports.deleteProcurementItem = exports.sendSignatureDocument = exports.attachSignedDocument = exports.attachSignature = exports.exportProcurementItems = exports.addUpdateTreasuryBudgetArticle = exports.getProjectsUsers = exports.getApprovedItemSubmissions = exports.getApprovedItemCpvCodes = exports.getVerifiedItems = exports.revertProcurement = exports.updateProcurementAdditionalData = exports.actuallyDoneProcurement = exports.suspendProcurement = exports.declineProcurement = exports.sendApproval = exports.sendForVerify = exports.createProcurement = exports.updateProcurement = exports.getProcurementsForInvoice = exports.getProcurementItems = exports.deleteProcurement = exports.getProcurements = exports.getProcurement = exports.deleteApprover = exports.addApprover = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../../privateRequest"));
const ApiV2 = tslib_1.__importStar(require("api/privateRequest-v2"));
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const parsers_1 = require("api/documents/purchases/procurements/parsers");
const parsers_2 = require("api/documents/purchases/procurementCorrections/parsers");
const parsers_3 = require("api/references/legalBasics/parsers");
const react_toastify_1 = require("react-toastify");
const addApprover = async (procurementId, params) => {
    const response = await Api.patch(`documents/purchases/procurements/${procurementId}/add-approver`, params);
    return { status: response.status, errors: response.errors };
};
exports.addApprover = addApprover;
const deleteApprover = async (approverId, params) => {
    return await Api.deleteItem('documents/purchases/procurements/approver/' + approverId, params);
};
exports.deleteApprover = deleteApprover;
const getProcurement = async (id, original) => {
    const response = await Api.get('documents/purchases/procurements/' + id);
    return (0, parsers_1.parseProcurement)(response, original);
};
exports.getProcurement = getProcurement;
const getProcurements = async (params) => {
    const response = await Api.get('documents/purchases/procurements', params);
    return (0, parsers_1.parseProcurements)(response);
};
exports.getProcurements = getProcurements;
const deleteProcurement = async (id) => {
    return await Api.deleteItem(`documents/purchases/procurements/${id}`);
};
exports.deleteProcurement = deleteProcurement;
const getProcurementItems = async (id, page) => {
    const response = await Api.get(`documents/purchases/procurements/${id}/items`, { page });
    return (0, parsers_1.parseProcurementItemsData)(response);
};
exports.getProcurementItems = getProcurementItems;
const getProcurementsForInvoice = async (params) => {
    const response = await Api.get('documents/purchases/invoices/procurements-filter-data', params);
    return (0, parsers_1.parseProcurementsForSelect)(response);
};
exports.getProcurementsForInvoice = getProcurementsForInvoice;
const updateProcurement = async (id, params, original) => {
    const response = await Api.patch('documents/purchases/procurements/' + id, params);
    return (0, parsers_1.parseUpdateProcurement)(response, original);
};
exports.updateProcurement = updateProcurement;
const createProcurement = async (params, original) => {
    const response = await Api.post('documents/purchases/procurements', params);
    return (0, parsers_1.parseProcurement)(response, original);
};
exports.createProcurement = createProcurement;
const sendForVerify = async (procurementId) => {
    const response = await Api.patch('documents/purchases/procurements/send-for-verify/' + procurementId, {});
    return { status: response.status, errors: response.errors };
};
exports.sendForVerify = sendForVerify;
const sendApproval = async (procurementId, original) => {
    const response = await Api.patch('documents/purchases/procurements/approve/' + procurementId, {});
    return (0, parsers_1.parseProcurement)(response, original);
};
exports.sendApproval = sendApproval;
const declineProcurement = async (declineComment, procurementId, original) => {
    const response = await Api.patch('documents/purchases/procurements/decline/' + procurementId, { declineComment });
    return (0, parsers_1.parseProcurement)(response, original);
};
exports.declineProcurement = declineProcurement;
const suspendProcurement = async (suspendComment, procurementId, original) => {
    const response = await Api.patch('documents/purchases/procurements/suspend/' + procurementId, { suspendComment });
    return (0, parsers_1.parseProcurement)(response, original);
};
exports.suspendProcurement = suspendProcurement;
const actuallyDoneProcurement = async (comment, procurementId, original) => {
    const response = await Api.patch('documents/purchases/procurements/actually-done/' + procurementId, { comment });
    return (0, parsers_1.parseProcurement)(response, original);
};
exports.actuallyDoneProcurement = actuallyDoneProcurement;
const updateProcurementAdditionalData = async (procurementId, params) => {
    const response = await Api.patch('documents/purchases/procurements/update-additional-data/' + procurementId, params);
    return { status: response.status, errors: response.errors };
};
exports.updateProcurementAdditionalData = updateProcurementAdditionalData;
const revertProcurement = async (procurementId, params, original) => {
    const response = await Api.patch('documents/purchases/procurements/change-status-to-reverted/' + procurementId, params);
    return (0, parsers_1.parseProcurement)(response, original);
};
exports.revertProcurement = revertProcurement;
const getVerifiedItems = async (params) => {
    const response = await Api.get('documents/purchases/procurements/verified-items', params);
    return (0, parsers_1.parseVerifiedItems)(response);
};
exports.getVerifiedItems = getVerifiedItems;
const getApprovedItemCpvCodes = async () => {
    const response = await Api.get('documents/purchases/procurements/cpv-codes-filter-data');
    return (0, parsers_1.parseApprovedItemCpvCodesForSelect)(response);
};
exports.getApprovedItemCpvCodes = getApprovedItemCpvCodes;
const getApprovedItemSubmissions = async () => {
    const response = await Api.get('documents/purchases/procurements/submissions-filter-data');
    return (0, parsers_1.parseApprovedItemSubmissionsForSelect)(response);
};
exports.getApprovedItemSubmissions = getApprovedItemSubmissions;
const getProjectsUsers = async (procurementId) => {
    const response = await Api.get(`documents/purchases/procurements/${procurementId}/projects-users`);
    return (0, parsers_1.parseSuggestedApprovals)(response);
};
exports.getProjectsUsers = getProjectsUsers;
const addUpdateTreasuryBudgetArticle = async (params) => {
    const response = await Api.patch('documents/purchases/procurements/update-treasury-data', params);
    return { status: response.status, errors: response.errors };
};
exports.addUpdateTreasuryBudgetArticle = addUpdateTreasuryBudgetArticle;
const exportProcurementItems = async (id) => {
    const response = await Api.get(`documents/purchases/procurements/${id}/export`);
    return (0, parsers_1.parseExportProcurementItems)(response);
};
exports.exportProcurementItems = exportProcurementItems;
const attachSignature = async (id, params, original) => {
    const response = await Api.patch(`documents/purchases/procurements/${id}/attach-signature-document`, params);
    return (0, parsers_1.parseProcurement)(response, original);
};
exports.attachSignature = attachSignature;
const attachSignedDocument = async (id, params, original) => {
    const response = await Api.patch(`documents/purchases/procurements/${id}/attach-signed-document`, params);
    return (0, parsers_1.parseProcurement)(response, original);
};
exports.attachSignedDocument = attachSignedDocument;
const sendSignatureDocument = async (id, original) => {
    const response = await Api.patch(`documents/purchases/procurements/${id}/send-signature-document`, {});
    return (0, parsers_1.parseProcurement)(response, original);
};
exports.sendSignatureDocument = sendSignatureDocument;
const deleteProcurementItem = async (id, params) => {
    const response = await Api.deleteItem(`documents/purchases/procurements/${id}/items`, params);
    return { status: response.status, errors: response.errors };
};
exports.deleteProcurementItem = deleteProcurementItem;
const getProcurementCalculateItems = async (procurementId, mergedData = [], page = 1) => {
    const response = await Api.get(`documents/purchases/procurements/${procurementId}/items-history/ungroup`, { page });
    const content = response.content;
    //TODO @Merab დროებით მოგვარება როცა დიდი რაოდენობის საგნებია
    if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS && content.meta.pagination && content.meta.pagination.currentPage < content.meta.pagination.totalPages) {
        return (0, exports.getProcurementCalculateItems)(procurementId, mergedData.concat(content.data), page + 1).then();
    }
    if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS && content.meta.pagination && content.meta.pagination.totalPages > 1) {
        response.content.data = mergedData.concat(content.data);
    }
    return (0, parsers_1.parseProcurementCalculateItemsData)(response, procurementId);
};
exports.getProcurementCalculateItems = getProcurementCalculateItems;
const getProcurementCalculateItemsV2 = async (procurementId) => {
    const response = await ApiV2.get(`statistics/purchases/procurements/${procurementId}/funding-sources`);
    return (0, parsers_1.parseProcurementCalculateItemsV2)(response);
};
exports.getProcurementCalculateItemsV2 = getProcurementCalculateItemsV2;
const generateProcurementTemplate = async (procurementId) => {
    const response = await Api.patch(`documents/purchases/procurements/${procurementId}/generate-procurement-template`, {});
    return (0, parsers_1.parseProcurementTemplate)(response);
};
exports.generateProcurementTemplate = generateProcurementTemplate;
const getItemsForProcurement = async (procurementId, params) => {
    const response = await Api.get(`documents/purchases/procurements/${procurementId}/items`, params);
    return (0, parsers_1.parseProcurementItems)(response);
};
exports.getItemsForProcurement = getItemsForProcurement;
const updateProcurementItem = async (id, params) => {
    const response = await Api.patch(`documents/purchases/procurements/${id}/items`, params);
    return (0, parsers_1.parseProcurementItems)(response);
};
exports.updateProcurementItem = updateProcurementItem;
const getCorrectionCalculations = async (procurementId, mergedData = [], page = 1) => {
    const response = await Api.get(`documents/purchases/procurements/${procurementId}/correction/items-history/ungroup`, { page });
    //TODO @Merab Logic is transferred in backend and after that this should be removed
    const content = response.content;
    if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS && content.meta.pagination) {
        if (content.meta.pagination.currentPage < content.meta.pagination.totalPages) {
            return (0, exports.getCorrectionCalculations)(procurementId, mergedData.concat(content.data), page + 1).then();
        }
        response.content.data = mergedData.concat(content.data);
    }
    return (0, parsers_2.parseCorrectionCalculations)(response);
};
exports.getCorrectionCalculations = getCorrectionCalculations;
const getCorrectionCalculationsV2 = async (procurementCorrectionId) => {
    const response = await ApiV2.get(`statistics/purchases/procurements/${procurementCorrectionId}/corrections`);
    return (0, parsers_2.parseCorrectionCalculationsV2)(response);
};
exports.getCorrectionCalculationsV2 = getCorrectionCalculationsV2;
const deleteProcurementFile = async (id, params) => {
    return await Api.patch('documents/purchases/procurements/remove-files/' + id, params);
};
exports.deleteProcurementFile = deleteProcurementFile;
const getLegalBasicsForSelect = async (params) => {
    const response = await Api.get('references/legal-basis', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_3.parseLegalBasicsForSelect)(response);
};
exports.getLegalBasicsForSelect = getLegalBasicsForSelect;
const getProcurementTemplate = async (id) => {
    const response = await Api.get(`documents/purchases/procurements/${id}/get-template`);
    return (0, parsers_1.parseProcurementTemplate)(response);
};
exports.getProcurementTemplate = getProcurementTemplate;
const updateProcurementTemplate = async (id, params) => {
    const response = await Api.patch(`documents/purchases/procurements/${id}/update-template`, params);
    return (0, parsers_1.parseProcurementTemplate)(response);
};
exports.updateProcurementTemplate = updateProcurementTemplate;
const addAdditionalFiles = async (id, params) => {
    const response = await Api.patch(`documents/purchases/procurements/${id}/accounting-file-upload`, params);
    return (0, parsers_1.parseProcurementAdditionalFiles)(response);
};
exports.addAdditionalFiles = addAdditionalFiles;
const getAdditionalFiles = async (id) => {
    const response = await Api.get(`documents/purchases/procurements/${id}/accounting-files`);
    return (0, parsers_1.parseProcurementAdditionalFiles)(response);
};
exports.getAdditionalFiles = getAdditionalFiles;
const createOrUpdateProcurementMultiYear = async (id, params) => {
    const response = await Api.patch(`documents/purchases/procurements/${id}/amounts`, params);
    return (0, parsers_1.parseProcurementMultiYear)(response);
};
exports.createOrUpdateProcurementMultiYear = createOrUpdateProcurementMultiYear;
const getProcurementMultiYear = async (id, params) => {
    const response = await Api.get(`documents/purchases/procurements/${id}/amounts`, params);
    return (0, parsers_1.parseProcurementMultiYear)(response);
};
exports.getProcurementMultiYear = getProcurementMultiYear;
const deleteProcurementMultiYear = async (id) => {
    const response = await Api.deleteItem(`documents/purchases/procurements/amount/${id}`);
    return { status: response.status, errors: response.errors };
};
exports.deleteProcurementMultiYear = deleteProcurementMultiYear;
const getProcurementMultiYearHistory = async (id) => {
    const response = await Api.get(`documents/purchases/procurements/amount/${id}/history`);
    return (0, parsers_1.parseProcurementMultiYearHistory)(response);
};
exports.getProcurementMultiYearHistory = getProcurementMultiYearHistory;
