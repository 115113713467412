"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmployeeActiveSignatoryFiles = exports.getMajorContractsForSelect = exports.updateDismissal = exports.addDismissal = exports.deleteDismissal = exports.getDismissals = exports.deleteAssignment = exports.createEmployeeAssignmentCorrection = exports.notifyPendingContractFiles = exports.updateEmployeeAssignmentV2 = exports.createEmployeeAssignmentV2 = exports.createEmployeeAssignment = exports.getAssignment = exports.getAssignments = exports.updateTaxes = exports.getTaxOptions = exports.getTaxValues = exports.deleteDocumentFile = exports.deleteEducationSecondary = exports.deleteEducationProfessional = exports.deleteEducationHigh = exports.addTraining = exports.getTrainings = exports.getLanguages = exports.addLanguage = exports.deleteLanguage = exports.deleteTraining = exports.deleteFamilyMember = exports.addFamilyMember = exports.deleteBankAccount = exports.addBankAccount = exports.getBankAccount = exports.addOrUpdateEducationSecondary = exports.addOrUpdateEducationProfessional = exports.addOrUpdateEducationHigh = exports.getEducationSecondaries = exports.getEducationProfessionals = exports.getEducationHighs = exports.updateEmployeeDocumentFiles = exports.getEmployeeDocumentFiles = exports.updateEmployee = exports.createEmployee = exports.getEmployee = exports.deleteEmployee = exports.getEmployees = exports.getEmployeeForSelect = exports.getEmployeesForSelectStructuralUnits = exports.getEmployeesForSelectByPosition = exports.getEmployeesForSelectWithProjects = exports.getEmployeesForSelect = void 0;
exports.getAcademicDegreeForSelect = exports.deleteContractOrAnnexRecord = exports.deleteContractOrAnnexFile = exports.deleteEmployeeSignatoryFiles = exports.updateContractAndAnnexCorrection = exports.createContractAndAnnexCorrection = exports.appointedCandidateSign = exports.getEmployeeContract = exports.downloadUnauthorizedMediaFile = exports.sendContractAndAnnex = exports.updateContractAndAnnex = exports.createContractAndAnnex = exports.getContractAndAnnex = exports.updateEmployeeSignatoryFiles = exports.createEmployeeSignatoryFiles = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const PublicApi = tslib_1.__importStar(require("api/requests"));
const parsers_1 = require("api/references/employees/parsers");
const parseBankAccount_1 = require("api/references/employees/parseBankAccount");
const assignmentParser_1 = require("./assignmentParser");
const dismissalParser_1 = require("api/references/employees/dismissalParser");
const contractFileParser_1 = require("api/references/employees/contractFileParser");
const languageParser_1 = require("api/references/employees/languageParser");
const parsers_2 = require("api/files/parsers");
const parsTaxReliefs_1 = require("api/references/employees/parsTaxReliefs");
const trainingParser_1 = require("./trainingParser");
const react_toastify_1 = require("react-toastify");
const getEmployeesForSelect = async (params) => {
    const response = await Api.get('references/employees', params);
    return (0, parsers_1.parseEmployeesForSelect)(response);
};
exports.getEmployeesForSelect = getEmployeesForSelect;
const getEmployeesForSelectWithProjects = async (params) => {
    const response = await Api.get('references/employees', params);
    return (0, parsers_1.parseEmployeesForSelectWithProjects)(response);
};
exports.getEmployeesForSelectWithProjects = getEmployeesForSelectWithProjects;
const getEmployeesForSelectByPosition = async (params) => {
    const response = await Api.get('references/employees', params);
    return (0, parsers_1.parseEmployeesForSelectByPosition)(response);
};
exports.getEmployeesForSelectByPosition = getEmployeesForSelectByPosition;
const getEmployeesForSelectStructuralUnits = async (params) => {
    const response = await Api.get('references/employees', params);
    return (0, parsers_1.parseEmployeesForStructuralUnitSelect)(response);
};
exports.getEmployeesForSelectStructuralUnits = getEmployeesForSelectStructuralUnits;
const getEmployeeForSelect = async (id) => {
    const response = await Api.get('references/employees/' + id);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseEmployeeForSelect)(response);
};
exports.getEmployeeForSelect = getEmployeeForSelect;
const getEmployees = async (params) => {
    const response = await Api.get('references/employees', params);
    return (0, parsers_1.parseEmployees)(response);
};
exports.getEmployees = getEmployees;
const deleteEmployee = async (id) => {
    return await Api.deleteItem('references/employees/' + id);
};
exports.deleteEmployee = deleteEmployee;
const getEmployee = async (id) => {
    const response = await Api.get('references/employees/' + id);
    return (0, parsers_1.parseEmployee)(response);
};
exports.getEmployee = getEmployee;
const createEmployee = async (params) => {
    const response = await Api.post('references/employees', params);
    return (0, parsers_1.parseEmployee)(response);
};
exports.createEmployee = createEmployee;
const updateEmployee = async (id, params) => {
    const response = await Api.patch('references/employees/' + id, params);
    return (0, parsers_1.parseEmployee)(response);
};
exports.updateEmployee = updateEmployee;
const getEmployeeDocumentFiles = async (id) => {
    const response = await Api.get(`references/employees/media-files/${id}`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseEmployeeDocumentFiles)(response);
};
exports.getEmployeeDocumentFiles = getEmployeeDocumentFiles;
const updateEmployeeDocumentFiles = async (id, params) => {
    const response = await Api.post(`references/employees/media-files/${id}`, params);
    return { data: null, errors: response.errors, status: response.status };
};
exports.updateEmployeeDocumentFiles = updateEmployeeDocumentFiles;
const getEducationHighs = async (id) => {
    const response = await Api.get(`references/employees/education/${id}/high`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseEducationHighs)(response);
};
exports.getEducationHighs = getEducationHighs;
const getEducationProfessionals = async (id) => {
    const response = await Api.get(`references/employees/education/${id}/professional`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseEducationProfessionals)(response);
};
exports.getEducationProfessionals = getEducationProfessionals;
const getEducationSecondaries = async (id) => {
    const response = await Api.get(`references/employees/education/${id}/secondary`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseEducationSecondaries)(response);
};
exports.getEducationSecondaries = getEducationSecondaries;
const addOrUpdateEducationHigh = async (id, params) => {
    const response = await Api.post(`references/employees/education/${id}/high`, params);
    return (0, parsers_1.parseEducationHighs)(response);
};
exports.addOrUpdateEducationHigh = addOrUpdateEducationHigh;
const addOrUpdateEducationProfessional = async (id, params) => {
    const response = await Api.post(`references/employees/education/${id}/professional`, params);
    return (0, parsers_1.parseEducationProfessionals)(response);
};
exports.addOrUpdateEducationProfessional = addOrUpdateEducationProfessional;
const addOrUpdateEducationSecondary = async (id, params) => {
    const response = await Api.post(`references/employees/education/${id}/secondary`, params);
    return (0, parsers_1.parseEducationSecondaries)(response);
};
exports.addOrUpdateEducationSecondary = addOrUpdateEducationSecondary;
const getBankAccount = async (userId) => {
    const response = await Api.get(`references/employees/bank-accounts/${userId}`);
    return (0, parseBankAccount_1.parseBankAccounts)(response);
};
exports.getBankAccount = getBankAccount;
const addBankAccount = async (params, id) => {
    return await Api.post('references/employees/bank-accounts/' + id, params);
};
exports.addBankAccount = addBankAccount;
const deleteBankAccount = async (userId, bankAccountId) => {
    return await Api.deleteItem('references/employees/bank-accounts/' + userId, { ids: [bankAccountId] });
};
exports.deleteBankAccount = deleteBankAccount;
const addFamilyMember = async (params, id) => {
    const response = await Api.post('references/employees/family-members/' + id, params);
    return (0, parsers_1.parseEmployeeFamilyMember)(response);
};
exports.addFamilyMember = addFamilyMember;
const deleteFamilyMember = async (userId, familyMemberId) => {
    return await Api.deleteItem('references/employees/family-members/' + userId, { ids: [familyMemberId] });
};
exports.deleteFamilyMember = deleteFamilyMember;
const deleteTraining = async (userId, trainingId) => {
    return await Api.deleteItem('references/employees/training/' + userId, { ids: [trainingId] });
};
exports.deleteTraining = deleteTraining;
const deleteLanguage = async (userId, trainingId) => {
    return await Api.deleteItem('references/employees/language/' + userId, { ids: [trainingId] });
};
exports.deleteLanguage = deleteLanguage;
const addLanguage = async (params, id) => {
    const response = await Api.post(`references/employees/language/${id}`, params);
    return (0, languageParser_1.parseLanguages)(response);
};
exports.addLanguage = addLanguage;
const getLanguages = async (id) => {
    const response = await Api.get(`references/employees/language/${id}`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, languageParser_1.parseLanguages)(response);
};
exports.getLanguages = getLanguages;
const getTrainings = async (id) => {
    const response = await Api.get(`references/employees/training/${id}`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, trainingParser_1.parseTrainings)(response);
};
exports.getTrainings = getTrainings;
const addTraining = async (params, id) => {
    const response = await Api.post(`references/employees/training/${id}`, params);
    return (0, trainingParser_1.parseTrainings)(response);
};
exports.addTraining = addTraining;
const deleteEducationHigh = async (id, params) => {
    const response = await Api.deleteItem(`references/employees/education/${id}/high`, params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return response;
};
exports.deleteEducationHigh = deleteEducationHigh;
const deleteEducationProfessional = async (id, params) => {
    const response = await Api.deleteItem(`references/employees/education/${id}/professional`, params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return response;
};
exports.deleteEducationProfessional = deleteEducationProfessional;
const deleteEducationSecondary = async (id, params) => {
    const response = await Api.deleteItem(`references/employees/education/${id}/secondary`, params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return response;
};
exports.deleteEducationSecondary = deleteEducationSecondary;
const deleteDocumentFile = async (userId, params) => {
    const response = await Api.deleteItem(`references/employees/media-files/${userId}`, params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return response;
};
exports.deleteDocumentFile = deleteDocumentFile;
const getTaxValues = async (userId) => {
    const response = await Api.get('references/employees/tax-relief/' + userId);
    return (0, parsTaxReliefs_1.parseTaxValues)(response);
};
exports.getTaxValues = getTaxValues;
const getTaxOptions = async () => {
    const response = await Api.get('references/human-resources/tax-relief');
    return (0, parsTaxReliefs_1.parseTaxOptions)(response);
};
exports.getTaxOptions = getTaxOptions;
const updateTaxes = async (params, userId) => {
    const response = await Api.patch('references/employees/tax-relief/' + userId, params);
    return (0, parsTaxReliefs_1.parseTaxValues)(response);
};
exports.updateTaxes = updateTaxes;
const getAssignments = async (userId) => {
    const response = await Api.get('documents/human-resources/contracts', { filters: { userId: userId } });
    return (0, assignmentParser_1.parseEmployeeAssignmentsV2)(response);
};
exports.getAssignments = getAssignments;
const getAssignment = async (originalId) => {
    const response = await Api.get(`documents/human-resources/contracts/${originalId}`);
    return (0, assignmentParser_1.parseEmployeeAssignment)(response);
};
exports.getAssignment = getAssignment;
const createEmployeeAssignment = async (params) => {
    const response = await Api.patch('documents/human-resources/contracts', params);
    return (0, assignmentParser_1.parseEmployeeAssignments)(response);
};
exports.createEmployeeAssignment = createEmployeeAssignment;
const createEmployeeAssignmentV2 = async (params) => {
    const response = await Api.post('documents/human-resources/contracts/from-structural-unit', params);
    return (0, assignmentParser_1.parseContractV2)(response, params.structuralUnitId);
};
exports.createEmployeeAssignmentV2 = createEmployeeAssignmentV2;
const updateEmployeeAssignmentV2 = async (assignId, params) => {
    const response = await Api.patch('documents/human-resources/contracts/from-structural-unit/' + assignId, params);
    return (0, assignmentParser_1.parseContractV2)(response, params.structuralUnitId);
};
exports.updateEmployeeAssignmentV2 = updateEmployeeAssignmentV2;
const notifyPendingContractFiles = async (params, notifyAgain) => {
    return await Api.patch(`references/employees/contract-files/notify${notifyAgain ? '-again' : ''}`, params);
};
exports.notifyPendingContractFiles = notifyPendingContractFiles;
const createEmployeeAssignmentCorrection = async (params, assignId) => {
    const response = await Api.patch(`documents/human-resources/contracts/${assignId}/correction`, params);
    return (0, assignmentParser_1.parseEmployeeAssignmentCorrection)(response);
};
exports.createEmployeeAssignmentCorrection = createEmployeeAssignmentCorrection;
const deleteAssignment = async (id) => {
    return await Api.deleteItem(`documents/human-resources/contracts/${id}`);
};
exports.deleteAssignment = deleteAssignment;
const getDismissals = async (params, userId) => {
    const response = await Api.get('documents/human-resources/dismissals', { ...params, filters: { userId: userId } });
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, dismissalParser_1.parseDismissals)(response);
};
exports.getDismissals = getDismissals;
const deleteDismissal = async (dismissalId) => {
    return await Api.deleteItem('documents/human-resources/dismissals/' + dismissalId);
};
exports.deleteDismissal = deleteDismissal;
const addDismissal = async (params) => {
    const response = await Api.post('documents/human-resources/dismissals', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, dismissalParser_1.parseDismissal)(response);
};
exports.addDismissal = addDismissal;
const updateDismissal = async (id, params) => {
    const response = await Api.patch(`documents/human-resources/dismissals/${id}`, params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, dismissalParser_1.parseDismissal)(response);
};
exports.updateDismissal = updateDismissal;
const getMajorContractsForSelect = async (params) => {
    const response = await Api.get('documents/human-resources/contracts', params);
    return (0, dismissalParser_1.parseMajorContractsForSelect)(response);
};
exports.getMajorContractsForSelect = getMajorContractsForSelect;
const getEmployeeActiveSignatoryFiles = async (userId) => {
    const response = await Api.get(`references/employees/${userId}/signatures`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseEmployeeSignatureAndApprove)(response);
};
exports.getEmployeeActiveSignatoryFiles = getEmployeeActiveSignatoryFiles;
const createEmployeeSignatoryFiles = async (userId, params) => {
    return await Api.post(`references/employees/${userId}/signatures`, params);
};
exports.createEmployeeSignatoryFiles = createEmployeeSignatoryFiles;
const updateEmployeeSignatoryFiles = async (userId, id, params) => {
    return await Api.put(`references/employees/${userId}/signatures/${id}`, params);
};
exports.updateEmployeeSignatoryFiles = updateEmployeeSignatoryFiles;
const getContractAndAnnex = async (employeeId) => {
    const response = await Api.get(`references/employees/${employeeId}/contract-files`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseGetContractAndAnnex)(response);
};
exports.getContractAndAnnex = getContractAndAnnex;
const createContractAndAnnex = async (employeeId, params) => {
    const response = await Api.post(`references/employees/${employeeId}/contract-files`, params);
    return (0, parsers_1.parseCreateUpdateContractAndAnnex)(response);
};
exports.createContractAndAnnex = createContractAndAnnex;
const updateContractAndAnnex = async (contractId, params) => {
    const response = await Api.patch(`references/employees/contract-files/${contractId}`, params);
    return (0, parsers_1.parseCreateUpdateContractAndAnnex)(response);
};
exports.updateContractAndAnnex = updateContractAndAnnex;
const sendContractAndAnnex = async (params) => {
    return await Api.patch('references/employees/contract-files/notify', params);
};
exports.sendContractAndAnnex = sendContractAndAnnex;
const downloadUnauthorizedMediaFile = async (uid) => {
    const response = await Api.get(`media-files/${uid}/preview/un-auth`);
    return (0, parsers_2.parseMediaFileForDownload)(response, true);
};
exports.downloadUnauthorizedMediaFile = downloadUnauthorizedMediaFile;
const getEmployeeContract = async (hash) => {
    const response = await PublicApi.get(`references/employees/contract-files/read/${hash}`);
    return (0, contractFileParser_1.parseEmployeeContract)(response);
};
exports.getEmployeeContract = getEmployeeContract;
const appointedCandidateSign = async (hash) => {
    const response = await PublicApi.patch(`references/employees/contract-files/appointed-candidate-sign/${hash}`, {});
    return (0, contractFileParser_1.parseEmployeeContract)(response);
};
exports.appointedCandidateSign = appointedCandidateSign;
const createContractAndAnnexCorrection = async (contractId, params) => {
    const response = await Api.post(`references/employees/contract-files/${contractId}/corrections`, params);
    return (0, parsers_1.parseContractAndAnnexCorrection)(response);
};
exports.createContractAndAnnexCorrection = createContractAndAnnexCorrection;
const updateContractAndAnnexCorrection = async (correctionId, params) => {
    const response = await Api.patch(`references/employees/contract-files/corrections/${correctionId}`, params);
    return (0, parsers_1.parseContractAndAnnexCorrection)(response);
};
exports.updateContractAndAnnexCorrection = updateContractAndAnnexCorrection;
const deleteEmployeeSignatoryFiles = async (signatoryId) => {
    return await Api.deleteItem('references/employees/signatures/' + signatoryId);
};
exports.deleteEmployeeSignatoryFiles = deleteEmployeeSignatoryFiles;
const deleteContractOrAnnexFile = async (uid) => {
    const response = await Api.deleteItem(`references/employees/contract-files/${uid}/remove`);
    return { status: response.status, errors: response.errors };
};
exports.deleteContractOrAnnexFile = deleteContractOrAnnexFile;
const deleteContractOrAnnexRecord = async (id) => {
    const response = await Api.deleteItem('references/employees/contract-files/' + id);
    return { status: response.status, errors: response.errors };
};
exports.deleteContractOrAnnexRecord = deleteContractOrAnnexRecord;
const getAcademicDegreeForSelect = async () => {
    const response = await Api.get('references/degrees');
    return (0, parsers_1.parseAcademicDegreesForSelect)(response);
};
exports.getAcademicDegreeForSelect = getAcademicDegreeForSelect;
