"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementCorrectionReducer = exports.useProcurementCorrection = exports.initialProcurementTemplateValue = exports.initialSubmissionsFormValues = exports.initialSubmissionFormValues = exports.initialCorrectionFormValues = void 0;
const react_1 = require("react");
const types_1 = require("api/documents/purchases/procurements/types");
const types_2 = require("api/documents/purchases/procurementCorrections/types");
const parsers_1 = require("api/documents/purchases/procurementCorrections/parsers");
const helper_1 = require("../../../../common/helper");
const initialCurrentVersion = {
    letterOrSubmissionId: '',
    correctionType: { id: String(types_2.ProcurementCorrectionType.LETTER), label: 'წერილი' },
    letterDate: '',
    id: '',
    disableCorrectionCreate: false,
    original: false,
    moduleType: 'ProcurementCorrection',
    number: '',
    signedDocumentNumber: '',
    dateOfConclusion: null,
    purchaseType: types_1.PurchaseType.ONE_YEAR_CONTRACT,
    visualPurchaseType: { name: 'ერთწლიანი ხელშეკრულება', color: 'secondary' },
    continuousContract: false,
    deliveryDate: (0, helper_1.getToday)(),
    contractValidityDate: (0, helper_1.getToday)(),
    advanceGuaranteeValidityPeriod: null,
    contractGuaranteeValidityPeriod: null,
    goodsGuaranteeValidityPeriod: null,
    templateType: types_1.TemplateTypes.ELECTRONIC,
    status: types_1.ProcurementStatuses.DRAFT,
    subStatus: types_1.ProcurementSubStatuses.NOT_EXECUTED,
    creator: null,
    type: null,
    remainingItemsQuantity: 0,
    declineMessage: null,
    actuallyDoneComment: null,
    sumPrice: 0,
    company: null,
    reasons: [],
    submissions: [],
    companyAddress: null,
    companyBankAccount: null,
    companySignatory: null,
    files: [],
    approvers: [],
    procurementFile: null,
    letterOrSubmissionFiles: [],
    lock: false,
    attachedFiles: [],
    attachedSignedFiles: [],
    isOther: false,
    revertComment: null,
    hasForceMajeureComment: false
};
exports.initialCorrectionFormValues = {
    correctionType: parsers_1.correctionType[1],
    submissionOrLetterType: types_2.ProcurementCorrectionType.LETTER,
    letterDate: '',
    letterOrSubmissionId: '',
    letterOrSubmissionFiles: [],
    reasons: [],
    submissions: []
};
exports.initialSubmissionFormValues = {
    id: null,
    submissionId: null,
    submissionAuthor: null,
    submissionAuthorPosition: null,
    submissionNumber: null,
    submissionRequestRegistration: null,
    submissionDeadline: null,
    submissionExecution: null,
    letterOrSubmissionFiles: [],
    selectAll: false
};
exports.initialSubmissionsFormValues = {
    reasons: [],
    submissions: [],
    reason: null
};
exports.initialProcurementTemplateValue = { content: '' };
const initialProcurementState = {
    data: {
        versions: null,
        currentVersion: initialCurrentVersion,
        original: null
    },
    templateData: exports.initialProcurementTemplateValue,
    errors: null,
    originalErrors: null,
    isUpdatingTreasuryBudgetingArticle: false,
    isUpdatingTreasuryProject: false,
    isFetching: false,
    originalIsFetching: false,
    isUpdating: false,
    isLoading: false,
    isDeclining: false,
    isApproving: false,
    isVerifying: false,
    isSuspending: false,
    isLocking: false,
    isLockingOut: false,
    isReverting: false,
    isAttachingSignature: false,
    isSendingSignedDocument: false,
    isSendingDocument: false,
    isActuallyDone: false,
    isTemplateUpdate: false,
    isTemplateFetching: false,
    isTemplateFetched: false,
    templateErrors: null,
    isGenerating: false
};
const useProcurementCorrection = () => {
    return (0, react_1.useReducer)(exports.ProcurementCorrectionReducer, initialProcurementState);
};
exports.useProcurementCorrection = useProcurementCorrection;
const ProcurementCorrectionReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_TEMPLATE_REQUEST':
            return {
                ...state,
                errors: null,
                isTemplateFetching: true,
                isLoading: true
            };
        case 'DONE_TEMPLATE_REQUEST':
            return {
                ...state,
                templateData: action.payload.data || state.templateData,
                errors: action.payload.errors
                    ? state.errors
                        ? state.errors.concat(action.payload.errors) : action.payload.errors
                    : state.errors,
                isTemplateFetched: true,
                isTemplateFetching: false,
                isLoading: state.isTemplateUpdate
                    || state.isSuspending
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
                    || state.isReverting
                    || state.isUpdating
            };
        case 'SEND_TEMPLATE_UPDATE':
            return {
                ...state,
                errors: null,
                isTemplateUpdate: true
            };
        case 'DONE_TEMPLATE_UPDATE':
            return {
                ...state,
                templateData: action.payload.data || state.templateData,
                templateErrors: action.payload.errors,
                isTemplateUpdate: false
            };
        case 'SEND_TEMPLATE_GENERATE':
            return {
                ...state,
                errors: null,
                isGenerating: true
            };
        case 'DONE_TEMPLATE_GENERATE':
            return {
                ...state,
                templateData: action.payload.data || state.templateData,
                errors: action.payload.errors,
                isGenerating: false
            };
        case 'SEND_PROCUREMENT_CORRECTIONS_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTIONS_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    versions: action.payload.data?.versions ?? state.data.versions,
                    currentVersion: action.payload.data?.versions?.[action.payload.data?.versions?.length - 1] ?? state.data.currentVersion
                },
                errors: action.payload.errors,
                isFetching: false,
                isLoading: false
            };
        case 'SEND_PROCUREMENT_CORRECTION_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTION_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    versions: state.data.versions && state.data.versions.map(el => {
                        return el.id === action.payload.data?.id ? { ...el, ...action.payload.data } : el;
                    }),
                    currentVersion: {
                        ...state.data.currentVersion,
                        ...action.payload.data
                    }
                },
                errors: action.payload.errors,
                isFetching: false,
                isLoading: false
            };
        case 'CHANGE_CURRENT_VERSION':
            return {
                ...state,
                data: {
                    ...state.data,
                    currentVersion: state.data.versions && state.data.versions[action.payload] || state.data.currentVersion
                }
            };
        case 'LOAD_ORIGINAL_VERSION':
            return {
                ...state,
                data: {
                    ...state.data,
                    currentVersion: state.data.original || state.data.currentVersion
                }
            };
        case 'SEND_PROCUREMENT_REQUEST':
            return {
                ...state,
                originalIsFetching: true
            };
        case 'DONE_PROCUREMENT_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    original: action.payload.data
                },
                templateErrors: action.payload.errors,
                originalIsFetching: false
            };
        case 'SEND_PROCUREMENT_CORRECTION_UPDATE':
            return {
                ...state,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTION_UPDATE':
            return {
                ...state,
                isUpdating: false,
                isLoading: false
            };
        case 'SEND_PROCUREMENT_CORRECTION_DECLINE':
            return {
                ...state,
                isDeclining: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTION_DECLINE':
            return {
                ...state,
                isDeclining: false,
                isLoading: false
            };
        case 'SEND_PROCUREMENT_CORRECTION_SUSPEND':
            return {
                ...state,
                isSuspending: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTION_SUSPEND':
            return {
                ...state,
                isSuspending: false,
                isLoading: false
            };
        case 'SEND_PROCUREMENT_CORRECTION_ACTUALLY_DONE':
            return {
                ...state,
                isActuallyDone: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTION_ACTUALLY_DONE':
            return {
                ...state,
                isActuallyDone: false,
                isLoading: false
            };
        case 'SEND_PROCUREMENT_CORRECTION_APPROVE':
            return {
                ...state,
                isApproving: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTION_APPROVE':
            return {
                ...state,
                isApproving: false,
                isLoading: false
            };
        case 'SEND_PROCUREMENT_CORRECTION_VERIFY':
            return {
                ...state,
                isVerifying: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTION_VERIFY':
            return {
                ...state,
                isVerifying: false,
                isLoading: false
            };
        case 'SEND_PROCUREMENT_CORRECTION_LOCK':
            return {
                ...state,
                isLocking: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTION_LOCK':
            return {
                ...state,
                isLocking: false,
                isLoading: false
            };
        case 'SEND_PROCUREMENT_CORRECTION_LOCKOUT':
            return {
                ...state,
                isLockingOut: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTION_LOCKOUT':
            return {
                ...state,
                isLockingOut: false,
                isLoading: false
            };
        case 'SEND_PROCUREMENT_CORRECTION_REVERT':
            return {
                ...state,
                isReverting: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_CORRECTION_REVERT':
            return {
                ...state,
                isReverting: false,
                isLoading: false
            };
        case 'SEND_UPDATE_TREASURY_BUDGET_ARTICLE_REQUEST':
            return {
                ...state,
                isUpdatingTreasuryBudgetingArticle: true
            };
        case 'DONE_UPDATE_TREASURY_BUDGET_ARTICLE_REQUEST':
            return {
                ...state,
                isUpdatingTreasuryBudgetingArticle: false
            };
        case 'SEND_UPDATE_TREASURY_PROJECT_REQUEST':
            return {
                ...state,
                isUpdatingTreasuryProject: true
            };
        case 'DONE_UPDATE_TREASURY_PROJECT_REQUEST':
            return {
                ...state,
                isUpdatingTreasuryProject: false
            };
        case 'UPDATE_LETTER_OR_SUBMISSION':
            return {
                ...state,
                data: {
                    ...state.data,
                    currentVersion: {
                        ...state.data.currentVersion,
                        ...action.payload
                    }
                }
            };
        case 'SEND_ATTACH_SIGNATURE':
            return {
                ...state,
                isAttachingSignature: true,
                isLoading: true
            };
        case 'DONE_ATTACH_SIGNATURE':
            return {
                ...state,
                data: {
                    ...state.data,
                    currentVersion: {
                        ...state.data.currentVersion,
                        ...action.payload.data
                    }
                },
                errors: action.payload.errors,
                isReverting: false,
                isAttachingSignature: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
            };
        case 'SEND_SIGNATURE_DOCUMENT':
            return {
                ...state,
                isSendingDocument: true,
                isLoading: true
            };
        case 'DONE_SIGNATURE_DOCUMENT':
            return {
                ...state,
                data: {
                    ...state.data,
                    currentVersion: {
                        ...state.data.currentVersion,
                        ...action.payload.data
                    },
                    versions: state.data.versions?.map(el => {
                        if (action.payload.data === null || el.id !== action.payload.data.id)
                            return el;
                        return {
                            ...el,
                            status: action.payload.data.status,
                            subStatus: action.payload.data.subStatus,
                            procurementVisualStatus: action.payload.data.procurementVisualStatus,
                            subVisualStatus: action.payload.data.subVisualStatus
                        };
                    }) || null
                },
                errors: action.payload.errors,
                isReverting: false,
                isSendingDocument: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
            };
        case 'SEND_ATTACH_SIGNATURE_DOCUMENT':
            return {
                ...state,
                isSendingSignedDocument: true,
                isLoading: true
            };
        case 'DONE_ATTACH_SIGNATURE_DOCUMENT':
            return {
                ...state,
                data: {
                    ...state.data,
                    currentVersion: {
                        ...state.data.currentVersion,
                        ...action.payload.data
                    }
                },
                errors: action.payload.errors,
                isReverting: false,
                isSendingSignedDocument: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isDeclining
                    || state.isApproving
                    || state.isVerifying
                    || state.isLocking
                    || state.isLockingOut
            };
        case 'UPDATE_PROCUREMENT_FILE_DATA':
            return {
                ...state,
                data: {
                    ...state.data,
                    currentVersion: {
                        ...state.data.currentVersion,
                        procurementFile: state.data.currentVersion.procurementFile && {
                            ...state.data.currentVersion.procurementFile,
                            link: action.payload.link
                        }
                    }
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.ProcurementCorrectionReducer = ProcurementCorrectionReducer;
