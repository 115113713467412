"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseUpdateAuthority = exports.parseStructuralUnitPosition = exports.parseStructuralUnitPositionsForSelect = exports.parseStructuralUnitPositions = exports.parseStructuralUnitForCards = exports.parseStructuralUnitsForSelect = exports.parseAncestors = exports.parseAttachedStructuralUnits = exports.parseStructuralUnitsToAttach = exports.parseAttachedEmployees = exports.parseHrEmployeeForStructuralUnit = exports.parseFundingSources = exports.parseOrders = exports.parsePositions = exports.parseStructuralUnit = exports.parseAttachInitialInfo = exports.parseStructuralUnitForSelect = exports.parseStructuralUnitsTree = void 0;
const types_1 = require("./types");
const helper_1 = require("api/helper");
const helpers_1 = require("core/helpers");
const parsers_1 = require("api/documents/orders/administrativeOrders/parsers");
const parsers_2 = require("api/references/funding/projects/parsers");
const constants_1 = require("src/modules/references/structuralUnitsUpgraded/helpers/constants");
const parsers_3 = require("api/parsers/parsers");
const parseStructuralUnitsTree = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnits = response.content;
    const structuralUnitsDataLength = structuralUnits.data.length;
    try {
        return {
            data: structuralUnits.data.map((el, index) => {
                const employee = el.relationships.account?.data.relationships.user.data.attributes.fullname;
                const userData = el.relationships.account?.data.relationships.user.data;
                const workDirectionName = el.relationships.workDirection?.data.attributes.name;
                return {
                    id: el.id,
                    fullName: (el.attributes.type === types_1.StructuralUnitType.POSITION ? el.attributes.name : el.attributes.fullName) + (workDirectionName ? ' ' + workDirectionName : ''),
                    authorizedAccount: el.relationships.account?.data.relationships.authorizedAccount ? {
                        id: el.relationships.account.data.relationships.authorizedAccount.data.relationships.activeAuthorizedAccountInfo.data.id,
                        status: el.relationships.account.data.relationships.authorizedAccount.data.attributes.status,
                        startDate: (0, parsers_3.transformDate)(el.relationships.account.data.relationships.authorizedAccount.data.attributes.startDate),
                        endDate: (0, parsers_3.transformDate)(el.relationships.account.data.relationships.authorizedAccount.data.attributes.endDate)
                    } : el.relationships.authorizedAccount ? {
                        id: el.relationships.authorizedAccount.data.relationships.activeAuthorizedAccountInfo.data.id,
                        status: el.relationships.authorizedAccount.data.attributes.status,
                        startDate: (0, parsers_3.transformDate)(el.relationships.authorizedAccount.data.attributes.startDate),
                        endDate: (0, parsers_3.transformDate)(el.relationships.authorizedAccount.data.attributes.endDate)
                    } : null,
                    positionName: el.attributes.type === types_1.StructuralUnitType.POSITION ? el.attributes.fullName : undefined,
                    hasChildren: el.attributes.hasChildren,
                    accountId: el.relationships.account?.data.id || null,
                    children: [],
                    name: el.attributes.type === types_1.StructuralUnitType.POSITION ? el.relationships.position.data.attributes.name : el.attributes.fullName,
                    parentIds: el.attributes.parentIds ? el.attributes.parentIds.map(el => String(el)) : [],
                    positionStatus: el.attributes.positionStatus,
                    status: el.attributes.status,
                    type: el.attributes.type,
                    useUnitPrefix: el.attributes.useUnitPrefix,
                    prefixForPosition: el.attributes.prefixForPosition,
                    employee: employee || null,
                    identifier: el.id + (0, helpers_1.getHash)(),
                    contractId: el.relationships.account?.data.relationships.contracts.data[0]?.id,
                    user: userData && { id: userData.id, label: userData.attributes.fullname },
                    iconType: el.attributes.type === 1 ? el.attributes.parentIds === null ? ' fa-university' : ' fa-home' : ' fa-user-tie',
                    isPermanent: (el.attributes.type === 2 && el.attributes.positionStatus === 2),
                    isLastNode: index === structuralUnitsDataLength - 1,
                    isOpen: false,
                    isUpdated: false,
                    parentName: el.relationships.parentUnit?.data.attributes.name,
                    positionType: el.relationships.positionType && {
                        id: el.relationships.positionType.data.id,
                        label: el.relationships.positionType.data.attributes.name,
                        index: el.relationships.positionType.data.attributes.index,
                        type: el.relationships.positionType.data.attributes.type
                    },
                    basicFunctionDuties: el.attributes.basicFunctionDuties
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_3.transformPagination)(structuralUnits.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseStructuralUnitsTree = parseStructuralUnitsTree;
const parseStructuralUnitForSelect = (response) => {
    if (response.content === null)
        return null;
    const structuralUnit = response.content;
    try {
        return {
            id: structuralUnit.data.id,
            label: structuralUnit.data.attributes.fullName
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return null;
    }
};
exports.parseStructuralUnitForSelect = parseStructuralUnitForSelect;
const parseAttachInitialInfo = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnit = response.content;
    try {
        return {
            data: {
                structuralUnit: {
                    id: structuralUnit.data.id,
                    label: structuralUnit.data.attributes.fullName
                },
                maxSalaryAmount: structuralUnit.data.attributes.maxSalaryAmount,
                minSalaryAmount: structuralUnit.data.attributes.minSalaryAmount
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAttachInitialInfo = parseAttachInitialInfo;
const parseStructuralUnit = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnit = response.content;
    try {
        return {
            data: parseStructuralUnAttributes(structuralUnit.data),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseStructuralUnit = parseStructuralUnit;
const parsePositions = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnits = response.content;
    try {
        return {
            data: structuralUnits.data.map(el => parseStructuralUnAttributes(el)),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePositions = parsePositions;
const parseStructuralUnAttributes = (structuralUnit) => {
    const parents = structuralUnit.relationships.parents.data;
    const type = constants_1.typeOptions.find(el => el.id === structuralUnit.attributes.type);
    const positionType = structuralUnit.relationships.positionType;
    const workDirection = structuralUnit.relationships.workDirection;
    return {
        id: structuralUnit.id,
        name: structuralUnit.attributes.fullName,
        structuralUnitName: structuralUnit.attributes.type === types_1.StructuralUnitType.STRUCTURAL_UNIT ? structuralUnit.attributes.fullName : '',
        positionName: structuralUnit.attributes.type === types_1.StructuralUnitType.POSITION ? {
            id: structuralUnit.relationships.position.data.id,
            label: structuralUnit.relationships.position.data.attributes.name
        } : null,
        positionStatus: constants_1.positionStatuses.find(el => el.id === structuralUnit.attributes.positionStatus) || null,
        positionType: positionType && {
            id: positionType.data.id,
            label: positionType.data.attributes.name
        },
        type,
        prefixForPosition: structuralUnit.attributes.prefixForPosition,
        basicFunctionDuties: structuralUnit.attributes.basicFunctionDuties,
        parents: parents.map(el => ({ id: el.id, label: el.attributes.fullName })),
        parent: parents[0] ? { id: parents[0].id, label: parents[0].attributes.fullName } : null,
        creator: structuralUnit.relationships.creator?.data.attributes.fullname,
        editor: structuralUnit.relationships.editor?.data.attributes.fullname,
        createdAt: (0, parsers_3.transformDate)(structuralUnit.attributes.createdAt, 'dd-MM-yyyy HH:mm:ss'),
        updatedAt: (0, parsers_3.transformDate)(structuralUnit.attributes.updatedAt, 'dd-MM-yyyy HH:mm:ss'),
        edocUser: structuralUnit.attributes.edocUser,
        workDirection: workDirection && {
            id: workDirection.data.id,
            label: workDirection.data.attributes.name
        },
        minSalaryAmount: structuralUnit.attributes.minSalaryAmount,
        maxSalaryAmount: structuralUnit.attributes.maxSalaryAmount
    };
};
const parseOrders = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const orders = response.content;
    try {
        return {
            data: {
                attachedOrders: orders.data
                    ? (0, parsers_1.parseAdministrativeOrdersForSelectType)(orders) : [],
                attachingOrder: null
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseOrders = parseOrders;
const parseFundingSources = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const projects = response.content;
    try {
        const attachedProjects = projects
            ? projects.data.map(el => ({ id: el.id, label: (0, parsers_2.parseProjectFullName)(el) }))
            : [];
        return {
            data: {
                attachedFundingSources: projects.data
                    ? attachedProjects : [],
                attachingFundingSource: null
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseFundingSources = parseFundingSources;
const parseHrEmployeeForStructuralUnit = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const hrEmployeeAccounts = response.content;
    try {
        return {
            data: hrEmployeeAccounts.data.map(el => {
                const structuralUnit = el.relationships.structuralUnit;
                const project = el.relationships.project;
                return {
                    accountId: el.id,
                    position: structuralUnit
                        ? structuralUnit.data.attributes.fullName
                        : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                    name: el.relationships.user.data.attributes.fullname,
                    attachedBy: null,
                    createdAt: null,
                    checked: false
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        console.error({ err });
        return helper_1.errorObject;
    }
};
exports.parseHrEmployeeForStructuralUnit = parseHrEmployeeForStructuralUnit;
const parseAttachedEmployees = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnits = response.content;
    const hrEmployees = structuralUnits.data.relationships.hrEmployeeAccounts;
    try {
        return {
            data: hrEmployees ? hrEmployees.data.map(el => {
                const structuralUnit = el.relationships.account.data.relationships.structuralUnit;
                const project = el.relationships.account.data.relationships.project;
                return {
                    accountId: el.relationships.account.data.id,
                    createdAt: (0, parsers_3.transformDateTime)(el.attributes.updatedAt),
                    attachedBy: el.relationships.creator.data.relationships.user.data.attributes.fullname,
                    authorPosition: structuralUnit
                        ? structuralUnit.data.attributes.fullName
                        : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                };
            }) : [],
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        console.error({ err });
        return helper_1.errorObject;
    }
};
exports.parseAttachedEmployees = parseAttachedEmployees;
const parseStructuralUnitsToAttach = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnits = response.content;
    try {
        return {
            data: structuralUnits.data.map(el => {
                const workDirectionName = el.relationships.workDirection?.data.attributes.name;
                return {
                    id: el.id,
                    fullName: el.attributes.fullName + (workDirectionName ? ' ' + workDirectionName : ''),
                    checked: false
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_3.transformPagination)(structuralUnits.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseStructuralUnitsToAttach = parseStructuralUnitsToAttach;
const parseAttachedStructuralUnits = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnits = response.content;
    try {
        return {
            data: structuralUnits.data ? structuralUnits.data.map(el => {
                return {
                    structuralUnitId: el.id
                };
            }) : [],
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        console.error({ err });
        return helper_1.errorObject;
    }
};
exports.parseAttachedStructuralUnits = parseAttachedStructuralUnits;
const parseAncestors = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const ancestors = response.content.data.attributes;
    try {
        return {
            data: ancestors.map(structuralUnit => structuralUnit.map(unit => {
                return {
                    id: unit.id,
                    name: unit.name,
                    positionStatus: unit.positionStatus,
                    type: unit.type
                };
            }).reverse()),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAncestors = parseAncestors;
const parseStructuralUnitsForSelect = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const structuralUnits = response.content;
    try {
        return {
            data: structuralUnits.data.map(el => {
                return {
                    id: el.id,
                    label: el.attributes.name,
                    type: el.attributes.type,
                    positionStatus: el.attributes.positionStatus
                    // positionType: el.attributes.positionType
                };
            }),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: response.errors };
    }
};
exports.parseStructuralUnitsForSelect = parseStructuralUnitsForSelect;
const parseStructuralUnitForCards = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnit = response.content;
    try {
        return {
            data: {
                attachedStructuralUnits: structuralUnit
                    ? structuralUnit.data.map((el => ({ id: el.id, label: el.attributes.fullName })))
                    : []
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseStructuralUnitForCards = parseStructuralUnitForCards;
const parseStructuralUnitPositions = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnitPositions = response.content;
    try {
        return {
            data: structuralUnitPositions.data.map(el => el.attributes.fullName),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseStructuralUnitPositions = parseStructuralUnitPositions;
const parseStructuralUnitPositionsForSelect = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const structuralUnitPositions = response.content;
    try {
        return {
            data: structuralUnitPositions.data.map(el => {
                return {
                    id: el.id,
                    label: el.attributes.name
                };
            }),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: response.errors };
    }
};
exports.parseStructuralUnitPositionsForSelect = parseStructuralUnitPositionsForSelect;
const parseStructuralUnitPosition = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnitPositionParams = response.content;
    try {
        return {
            data: {
                id: structuralUnitPositionParams.data.id,
                name: structuralUnitPositionParams.data.attributes.name
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseStructuralUnitPosition = parseStructuralUnitPosition;
const parseUpdateAuthority = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const authority = response.content;
    try {
        return {
            data: {
                endDate: authority.data.attributes.endDate,
                deleteComment: null,
                authorizer: {
                    id: authority.data.id,
                    label: authority.data.relationships.authorizedAccount.data.relationships.user.data.attributes.fullname,
                    accountId: authority.data.relationships.authorizedAccount.data.id,
                    structuralUnitName: authority.data.relationships.authorizedUserAccount.data.relationships.structuralUnit.data.attributes.name
                },
                startDate: authority.data.attributes.startDate
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseUpdateAuthority = parseUpdateAuthority;
