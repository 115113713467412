"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StructuralUnitFormReducer = exports.useStructuralUnitFormReducer = void 0;
const react_1 = require("react");
const constants_1 = require("../helpers/constants");
const initialStructuralUnitFormValues = {
    id: '',
    name: '',
    structuralUnitName: '',
    positionName: null,
    prefixForPosition: null,
    parents: null,
    parent: null,
    type: constants_1.typeOptions[0],
    positionType: null,
    positionStatus: constants_1.positionStatuses[0],
    basicFunctionDuties: '',
    createdAt: '',
    updatedAt: '',
    edocUser: false,
    workDirection: null,
    maxSalaryAmount: null,
    minSalaryAmount: null
    // contract: null
};
const initialStructuralUnitForm = {
    data: initialStructuralUnitFormValues,
    changedIds: [],
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useStructuralUnitFormReducer = () => {
    return (0, react_1.useReducer)(exports.StructuralUnitFormReducer, initialStructuralUnitForm);
};
exports.useStructuralUnitFormReducer = useStructuralUnitFormReducer;
const StructuralUnitFormReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_STRUCTURAL_UNIT_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_STRUCTURAL_UNIT_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isUpdating || state.isCreating
            };
        case 'SEND_CREATE_STRUCTURAL_UNIT':
            return {
                ...state,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_CREATE_STRUCTURAL_UNIT':
            return {
                ...state,
                data: action.payload.data || state.data,
                changedIds: (action.payload.data && [action.payload.data.id]) || state.changedIds,
                isCreating: false,
                isLoading: state.isUpdating || state.isFetching
            };
        case 'SEND_CREATE_POSITION_UNIT':
            return {
                ...state,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_CREATE_POSITION_UNIT':
            return {
                ...state,
                data: (action.payload.data && action.payload.data[0]) || state.data,
                changedIds: action.payload.data?.map(el => el.id) || state.changedIds,
                isCreating: false,
                isLoading: state.isUpdating || state.isFetching
            };
        case 'SEND_UPDATE_STRUCTURAL_UNIT':
            return {
                ...state,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_UPDATE_STRUCTURAL_UNIT':
            return {
                ...state,
                data: action.payload.data || state.data,
                changedIds: (action.payload.data && [action.payload.data.id]) || state.changedIds,
                isUpdating: false,
                isLoading: state.isFetching || state.isCreating
            };
        case 'INITIALIZE_CREATE_FORM':
            return {
                ...state,
                data: {
                    ...initialStructuralUnitFormValues,
                    parents: [
                        { id: action.payload.id, label: action.payload.name }
                    ],
                    parent: { id: action.payload.id, label: action.payload.name },
                    prefixForPosition: action.payload.prefixForPosition
                },
                isFetching: false,
                isLoading: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.StructuralUnitFormReducer = StructuralUnitFormReducer;
